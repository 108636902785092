<template>
    <AppWrapper>
        <AppHeader></AppHeader>
        <AppBody :bodyClass="bodyClass">
            <slot />
            <LazyAppFooterMenu v-if="showFooterMenu"></LazyAppFooterMenu>
        </AppBody>
        <LazyAppFooter v-if="showFooter"></LazyAppFooter>
    </AppWrapper>
</template>

<script setup>
const route = useRoute();

const {isLoggedIn} = storeToRefs(useStoreAuth());

const bodyClass = computed(() => {
    if ([
        'login',
        'login-code',
        'login-index-passcode',
    ].includes(route.name)) return 'h-screen';
    return 'mb-auto';
});

const showFooterMenu = computed(() => {
    if (!isLoggedIn.value) return false;
    return ['index', 'courses', 'videos', 'live'].includes(route.name);
});

const showFooter = computed(() => {
    return ![
        'login',
        'login-code',
        'login-index-passcode',
        'messages',
        'messages-index-idThread',
        'messages-index-idThread-token',
    ].includes(route.name);
});
</script>
